<template>
    <div class="hotKeyword-content">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/index' }">店铺选品</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/industrymarket' }">数据分析</el-breadcrumb-item>
            <el-breadcrumb-item>热门关键词</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">热门关键词</div>
        <div class="goodsPurchase-container">
            <el-table :data="keywordsList" border style="width: 100%;" size="medium"
                      @sort-change="sortChange"
                      :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#333', height: '61px'}">
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <div class="search-keywords">
                            关键词
                            <el-input v-model="searchValue" @change="searchBtn" clearable size="small" placeholder="输入关键词搜索"/>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <span>{{ scope.row.keyword }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="search_num" align="center" label="搜索量" sortable="custom"></el-table-column>
                <el-table-column prop="click_rate" align="center" label="点击率" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.click_rate}}%
                    </template>
                </el-table-column>
                <el-table-column prop="tran_rate" align="center" label="转化率" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.tran_rate}}%
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchValue: '',
                keywordsList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                order_name: void 0,
                order_type: void 0,
            }
        },
        mounted() {
            this.getKeywordsList()
        },
        methods: {
            // 获取关键词列表
            getKeywordsList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.order_name) {
                    params.order_name = this.order_name
                }
                if (this.order_type) {
                    params.order_type = this.order_type
                }
                if (this.searchValue) {
                    params.name = this.searchValue
                }
                this.$http.axiosGetBy(this.$api.storeSelectionHotKeyWord, params, (res) => {
                    if (res.code === 200) {
                        this.keywordsList = res.data.list
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            sortChange(column, prop, order) {
                this.order_name = column.prop
                if (column.order === 'ascending') {
                    this.order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.order_type = 'desc'
                } else {
                    this.order_type = void 0
                }
                this.getKeywordsList()
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getKeywordsList()
            },
            // 搜索关键词
            searchBtn() {
                this.listPages.currentPageNum = 1
                this.getKeywordsList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .search-keywords {
        display: flex;
        align-items: center;
        .el-input {
            flex: 1;
            width: 1%;
            margin-left: 10px;
        }
    }
</style>